
.listeJoueurs {
    position: absolute;
    top: calc(100% + 10px);
    right: 12px;
    left: 12px;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
  }
  
  .listeJoueurs p {
    width: 33%;
  }
  
  .listeJoueurs p::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    right: 40px;
    left: 0;
    height: 1px;
    background-color: rgba(33, 37, 41,.1);
  }
  
  .listeJoueurs .playerName {
    cursor: pointer;
  }

  .btn-shoot {
    position: absolute;
    z-index: 2;
    top: -8px;
    right: 6px;
    width: fit-content;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    height: calc(100% - 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: inherit;
  }

  .input-notice {
    position: absolute;
    z-index: 2;
    top: 8px;
    left: 22px;
    width: 200px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    height: calc(100% - 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: inherit;
}

@media screen and (max-width: 768px) {
    .input-notice {
        position: absolute;
        z-index: 2;
        top: -40px;
        left: 12px;
    }
    .listeJoueurs p {
        width: 50%;
      }
  }

  
  
  
  
  @media screen and (max-width: 550px) {
      .listeJoueurs p {
        width: 100%;
      }
  }