.title-matches {
    font-weight: 800 !important;
    font-size: 34px;
  }
  
  .singleMatch .imgContainer img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .singleMatch {
    width: 100%;
  }
  .singleMatch .imgContainer {
    width: 50%;
    min-width: 50%;
    overflow: hidden;
    height: calc(100% + 30px);
    box-sizing: border-box;
    margin: -15px 20px -15px -15px;
  }
  
  .singleMatch .gameType {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 20px;
    font-size: 14px;
  }

  .singleMatch .profile-block h2 {
    font-size: 14px;
  }
  .singleMatch .profile-block small {
    font-size: 11px;
  }
  .singleMatch .profile-block {
    padding: 3px 20px !important;
    border-width: 4px !important;
    position: absolute;
    bottom: 4px;
    left: -10px;
    right: -10px;
    width: calc(100% + 10px) !important;
    border-radius: 0!important;
  }

  .singleMatch.placetop1:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(https://www.freepnglogos.com/uploads/crown-png/crown-transparent-crown-image-with-transparent-background-30.png);
    background-position: calc(100% + 20px) calc(100% + 20px);
    background-size: 200px;
    background-repeat: no-repeat;
    mix-blend-mode: saturation;
    opacity: .2;
  }

  .gameType.placetop1 .profile-block-small:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0;
    background-image: url(https://www.freepnglogos.com/uploads/crown-png/crown-transparent-crown-image-with-transparent-background-30.png);
    background-position: calc(100% + 3px) calc(100% + 0px);
    background-size: 30px;
    background-repeat: no-repeat;
    mix-blend-mode: saturation;
    opacity: .8;
  }

  .icon-death {
    max-width: 25px;
    margin: 0 0 0 -7px;
  }

  .low-lh {
    line-height: 12px;
  }

  .games-title small {
    display: block;
    margin-top: -2px;
  }

  .singleMatchSmall {
    max-width: fit-content;
  }
  
  .matchesDivSmall {
    margin: unset;
    max-width: 280px;
    min-width: 280px;
  }

  .matchesDivSmall .rowMatches {
    gap: 5px;
    padding-right: 0 !important;
    padding-left: 10px;
  }

  .smallLogo {
    width:20px;height:15px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .copyrightTracker{
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 265px;
    padding-top: 5px;
  }
 
  .w-fit {
    width: fit-content;
  }

  .profileTitleSmall {
    text-transform: uppercase;
    color: #e83283;
    font-size: 16px;
    font-weight: 900;
  }
  
  .profileTitleSmall small {
    font-size: 10px;
  }

  .profileTitleSmall small small{
    font-size: 10px;
  }

  body.tracker:not(.has-cfg) footer, body.tracker:not(.has-cfg) #logoTop {
    display: none;
  }

  body.tracker:not(.has-cfg) .trackerMain {
    padding: 0 !important;
  }

  body.tracker:not(.has-cfg) {
    backdrop-filter: unset !important;
  }

  body.tracker.has-cfg .tracker-outer {
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 130px);
    display: flex;
    justify-content: center;
    padding-top: 100px;
  }

  body.tracker #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .bg-red {
    background-color: red !important;
  }

  .bg-redlight {
    background-color: #c34242 !important;
  }

  .btn-outline-redlight {
    border: 1px solid #c34242 !important;
    color: #c34242 !important;
  }

  .btn-red {
    border: 1px solid red !important;
    color: red !important;
  }

  .text-red {
    color: red !important;
  }

  .text-redlight {
    color: #c34242 !important;
  }

  .bg-purple {
    background-color: rgba(102,69,187,.5);
  }

  .text-purple {
    color: rgba(102,69,187,.5);
  }

  .btn-purple {
    border: 1px solid rgba(102,69,187,.5) !important;
    color: rgba(102,69,187,.5);
  }

  .btn-outline-purple {
    border: 1px solid rgba(102,69,187,.5) !important;
    color: rgba(102,69,187,.5);
  }

  .bg-lightgreen {
    background-color: rgba(127,253,101,.2);
  }

  .text-lightgreen {
    color: rgb(24, 83, 12);
  }

  .btn-lightgreen {
    border: 1px solid rgba(127,253,101,.2) !important;
    color: rgb(24, 83, 12);
  }

  .btn-outline-lightgreen {
    border: 1px solid rgba(127,253,101,.2) !important;
    color: rgb(24, 83, 12);
  }

  .fs-xs {
    font-size: 8px;
  }
  .fs-small {
    font-size: 10px;
  }

  .trackerConfig .form-group > label {
    width: 100%;
    padding: 5px 0;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
  }
  .trackerConfig .form-group > label > small {
    font-size: 8px;
    white-space: normal;
    max-width: 90%;
  }
  .trackerConfig {
    display: none;
  }
  .trackerConfig input[type="radio"] {
    padding: 0 0 0 17px !important;
    margin: 0 !important;
  }

  input#trackerLink {
    font-size: 12px;
    padding: 5px !important;
    line-height: 10px;
    height: 40px;
  }

  .tracker .form-group {
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 0 10px;
    border-bottom: 1px solid #303235;
  }

  .tracker .form-group div:first-of-type input {
    display: none!important;
  }
  .tracker .form-group div:last-of-type input {
    display: none!important;
  }
  .tracker .form-group div:nth-of-type(2) input {
    display: none!important;
  }
  .tracker .form-group div:nth-of-type(3) input {
    display: none!important;
  }
  .tracker .form-group div input + label {
    background-color: rgb(67 67 68);
    width: 28px;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition : all .3s ease;
  }
  .tracker .form-group div:first-of-type input +label {
    border-radius: 50% 0 0 50% !important;
    box-shadow: unset !important;
  }
  .tracker .form-group div:first-of-type input:checked +label {
    background-color: rgb(24 114 84);
  }
  .tracker .form-group div:last-of-type input +label {
    border-radius: 0 50% 50% 0 !important;
    box-shadow: unset !important;
  }
  .tracker .form-group div:last-of-type input:checked +label {
    background-color: rgb(255, 0, 47);
  }
  .tracker .form-group div:nth-of-type(2) input +label {
    box-shadow: unset !important;
  }
  .tracker .form-group div:nth-of-type(2):not(:last-of-type) input:checked +label {
    background-color: rgb(255, 170, 0);
  }

  .tracker .form-group div:nth-of-type(3):not(:last-of-type) input:checked +label {
    background-color: rgb(255, 115, 0);
  }

  .tracker .form-group label {
    font-size: 12px;
    text-transform: uppercase;
    padding: 0;
  }

  body.tracker.has-cfg .trackerConfig {
    display: block !important;
    margin: -2px;
    top: 10px;
    max-width: 100%;
  }

  body.tracker:not(.has-cfg) {
    background: unset !important;
  }

  body.tracker.no-updated .trackerUpdated {
    display: none;
  }

  body.tracker.no-rank .trackerRank {
    display: none;
  }
  
  .matchesDivSmall .singleMatchSmall:nth-child(n+9) {
    display: none !important;
  }

  body.games-4 .matchesDivSmall .singleMatchSmall:nth-child(n+5) {
    display: none !important;
  }

  body.tracker.games-4.no-profiles .trackerContainer,
  body.tracker.games-4.no-profiles .trackerContainer > * {
    width: fit-content;
  }

  body.tracker.games-4.no-profiles .matchesDivSmall  {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  body.no-profiles .rowMatches h2  {
    font-size: 14px;
  }

  body.tracker.games-12 .matchesDivSmall .singleMatchSmall:nth-child(n+9),
  body.tracker.games-10 .matchesDivSmall .singleMatchSmall:nth-child(n+9) {
    display: block !important;
  }

  body.tracker.games-12 .matchesDivSmall .singleMatchSmall:nth-child(n+13) {
    display: none !important;
  }

  body.tracker.games-10 .matchesDivSmall .singleMatchSmall:nth-child(n+11) {
    display: none !important;
  }
  
  body.tracker.no-profiles #matchesDiv .singleMatchSmall .profile-block-small {
    background-color: transparent !important;
    background-image: linear-gradient(125deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) 70%)!important;
    padding-bottom: 0 !important;
    width: 29px !important;
    padding-top: 3px !important;
  }

  body.tracker.no-profiles #matchesDiv .singleMatchSmall .profile-block-small h2 {
    border: unset !important;
  }

  body.tracker.no-profiles #matchesDiv .singleMatchSmall .profile-block-small small {
    display: none !important;
  }

  body.tracker.no-width .matchesDivSmall {
    max-width: unset;
    min-width: unset;
  }

  @media screen and (min-width: 768px) {
    body.tracker.has-cfg .trackerConfig {
      max-width: 250px;
      position: fixed;
      right: 10px;
    }
    body.tracker.no-width.has-cfg .trackerMain {
      width: calc(100% - 300px);
    }
    body.tracker.has-cfg .tracker-outer {
      max-width: calc(100vw - 280px);
    }
  }

  body.tracker.no-width .trackerContainer {
    width: 100% !important;
  }

  .trackerContainer {
    width: fit-content;
    padding: 10px;
  }

  body.tracker.no-overlay .trackerMain {
    padding: 2px !important;
  }

  body.tracker.no-overlay .trackerContainer {
    background: unset !important;
    border-radius: unset !important;
    padding: 0px !important;
    overflow: unset;
  }

  body.tracker.no-overlay .trackerContainer * {
    text-shadow: 0 0 2px rgba(0,0,0,.8) !important;
  }
  .profile-tooltip {
    position: relative;
    border: 1px solid white;
    border-radius: 50%;
    font-size: 10px !important;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    cursor:help;
  }
  .profile-tooltip:hover {
    opacity: 1;
  }
  .profile-tooltip em {
    position: absolute;
    bottom: -5px;
    right: calc(100% - 28px);
    line-height: 10px;
    width: 160px;
    padding: 3px;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 3px 3px 0 3px;
    color: black;
    font-size: 10px;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all .3s ease;
  }

  .profile-tooltip:hover em {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }