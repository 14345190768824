.alerts-container {
    position: fixed;
    right: -300px;
    top: 10px;
    width: 250px;
    z-index: 999;
    transition: all .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  
  .alerts-container.show-alert {
    right: 10px;
  }
  
  .alerts-container .alert .title-alert{
    font-size: 12px;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
  }