.loading {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading > div {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.loading > div > img{
    width: calc(100% + 500px);
    margin: -165px -250px;
    mix-blend-mode: color-dodge;
}
.loading > p {
    width: 200px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    margin-top: 10px;
    color: #333333;
    mix-blend-mode: exclusion;
}

.loading > p::after {
    content: " ...";
}