.mainProfile .mainPro {
    position: relative;
    background: rgba(0,0,0,.1)url('../../../assets/images/bg-dot-small.png');
    background-size: 2px 2px;
    padding: 2rem;
    overflow: hidden;
    height: 100%;
    text-align: left;
}
.mainProfile .mainPro:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 0;
    background: url('../../../assets/images/pubg-profil.png') no-repeat top left;
    background-size: auto 130%;
    width: 100%;
    height: 120%;
    max-width: 1119px;
    max-height: 732px;
    mix-blend-mode: overlay;
    opacity: 1;
    pointer-events: none;
}


.mainPro.Season:after {
  background: url('../../../assets/images/pubg-season-profil.png') no-repeat bottom left;
  background-size: auto 110%;  
}

.mainPro.Season.CurrentSeason:after {
  background: url('../../../assets/images/pubg-current-season-profil.png') no-repeat bottom left;
  background-size: auto 110%;
}

.mainPro > .profile-block {
  margin-left: 10rem;
  width: calc(100% - 5rem) !important;
}

.mainPro > .profile-block h2 {
    font-size: 44px;
}

.mainPro > .profile-block p {
  font-size: 20px;
  line-height: 24px;
}

.mainPro > .profile-block small {
  font-size: 14px;
  display: none;
}


.profile-block {
  width: fit-content;
  margin: 0 auto;
  min-width: 30%;
}

.profile-block h2 {
  font-size: 20px;
}

.profile-block p {
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0;
}

.profile-block small {
  white-space: break-spaces;
}

.profile-block-small {
  width: 63px !important;
  margin: 0 auto;
  min-width: 30%;
  line-height: 6px;
  padding: 1px 0 4px !important;
  text-align: center;
}

.profile-block-small h2 {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid rgba(255,255,255,.1);
  width: 70%;
  margin: 0 auto 3px;
}

.profile-block-small p {
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0;
}

.profile-block-small small {
  font-size: 9px;
  white-space: nowrap;
}

  .ProfilesSwitch .btn {
    font-size: 12px;
  }

  .mainProfile.container {
    max-width: 1920px;
  }
  @media screen and (max-width: 600px) {
    .closetobe {
        flex-direction: column;
    }
    .profile-block {
        min-width: 90%;
      }
      .mainProfile .mainPro > h2 {
        font-size: 7vw;
        line-height: 8vw;
    }
    .mainProfile .mainPro > p {
        font-size: 3.5vw;
        line-height: 4.5vw;
    }
  }
  @media screen and (max-width: 768px) {
    .mainPro > .profile-block {
        margin-left: unset;
        margin: 0 auto;
        width: 100% !important;
    }
    .mainPro > .profile-block h2 {
        font-size: 34px;
    }
    
  }