.MapMostPlayed {
    position: relative;
    min-height: 30vh;
  }

  .MapMostPlayed--bg {
    opacity: 0.35;
    background: rgb(45,42,38);
 }

 .MapMostPlayed--bg:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: -moz-linear-gradient(180deg, rgba(45,42,38,1) 0%, rgba(45,42,38,0) 50%, rgba(45,42,38,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(45,42,38,1) 0%, rgba(45,42,38,0) 50%, rgba(45,42,38,1) 100%);
    background: linear-gradient(180deg, rgba(45,42,38,1) 0%, rgba(45,42,38,0) 50%, rgba(45,42,38,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d2a26",endColorstr="#2d2a26",GradientType=1);
 }
  
  .MapMostPlayed > div {
    background-size: cover;
    background-position: center center;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 900 !important;
    color: white !important;
    padding: 5px 20px 0;
    font-size: 30px;
    line-height: 50px;
  }

  .MapMostPlayed > div:not(.MapMostPlayed--bg) {
    transform: translateY(-50%);
    top: 50%;
    bottom: unset;
  }
  
  .MapMostPlayed--bigger {
    font-size: 60px;
  }

  .MapMostPlayed--desc {
    font-size: 20px;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    .MapMostPlayed {
       min-height: 50vh;
    }
    .MapMostPlayed > div {
      font-size: 5vw;
      line-height: 8vw;
      padding: 5px 20px 10vh;
    }

    .MapMostPlayed > div > div {
      width: 75% !important;
    }
    
    .MapMostPlayed--bigger {
      font-size: 10vw;
    }
  
    .MapMostPlayed--desc {
      font-size: 5vw;
    }
  }