body {
  font-family: 'Mulish', sans-serif;
  background: #2d2a26 url('../src/assets/images/bg-home.jpg') top center no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  font-size: 18px;
  overflow-x: hidden;
  backdrop-filter: brightness(.7);
}

body.profile {
  background-color: #2d2a26;
  background-image: url('../src/assets/images/bg-profil.png'), url('../src/assets/images/bg.png');
  background-size: 100vw, contain;
  background-attachment: unset;
  background-repeat: no-repeat, repeat;
  backdrop-filter: unset;
}
body.notfound {
  background: #2d2a26 url('../src/assets/images/pubg-404.jpg') top center no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  backdrop-filter: brightness(.4);
}

body.tracker {
  background: #2d2a26 url('../src/assets/images/bg-tracker.jpg') top center no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  backdrop-filter: brightness(.7);
}

p {
  margin-bottom: 0;
}

.fullwidth {
  min-width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

small {
  font-size: 12px;
}

h6 {
  position: relative;
  width: fit-content;
  padding-bottom: 10px;
}

h6:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #ffffff16;
}

.logo img {
  max-width: 80vw;
}

.container-perso-home {
  max-width: 890px;
  padding: 0 15px;
}

.container-notfound {
  min-height: 65vh;
}

body.profile footer {
  position: relative;
}

footer small {
  display: block;
  text-align: center;
  max-width: 560px;
  padding: 10px 15px;
  margin: 0 auto;    
  position: relative;
  z-index: 2;
}


.footer-gifts_texts {
  background: #e83283 url('../src/assets/images/bg-dots.png');
  max-width: calc(100vw - 296px);
  padding-right: 20vw !important;
}


.footer-gifts:after {
  content: "";
  background: url('../src/assets/images/bg-persos.png') no-repeat bottom center;
  background-size: contain;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 450px;
  max-width: 50%;
  height: 475px;
  display: block;
  pointer-events: none;
}

.legals {
  background-color: #212529;
  margin: 0 -12px;
  padding: 10px;
  font-size: 10px;
  font-weight: bolder;
}

.legals img {
  max-height: 20px;
  margin-right: 10px;
  width: 30px;
}

.legals .footer-twitter {
  max-height: unset;
  margin-right: 4px;
  width: 15px;
}

.backdrop {
  backdrop-filter: blur(5px);
  opacity: 1 !important;
}
.no-backdrop {
  backdrop-filter: blur(0) !important;
}
.overlay {
  border: none !important;
  background-color: transparent;
  background-image: linear-gradient(125deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) 70%);
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;
  color: #fff !important;
  font-weight: 700 !important;
  border-radius: 10px;
  font-size: 16px;
  padding: 15px;
}
.overlay-light {
  border: none !important;
  background-color: transparent;
  background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3) 70%);
  backdrop-filter: blur(5px);
  position: relative;
  overflow: hidden;
  color: #212529 !important;
  font-weight: 700 !important;
  border-radius: 10px;
  font-size: 16px;
  padding: 15px;
}

input {
    border: none !important;
    background-color: transparent;
    background-image: linear-gradient(125deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2) 70%);
    backdrop-filter: blur(5px);
    position: relative;
    overflow: hidden;
    color: #212529 !important;
    font-weight: 800 !important;
    padding-left: 220px !important;
}


.profileTitle {
  font-size: 4vw;
  text-transform: uppercase;
  font-weight: 900;
  max-width: 90vw;
}

.profileTitle span {
  display: inline-block;
  color: #e83283;
  font-weight: 900;
}

.profileTitle img {
  max-width: 7vw;
  margin: -5px 5px 0;
}


.deleteIcon {
  width: 18px;
  margin-right: 40px;
  cursor: pointer;
}

.badge.btn {
  cursor: initial;
}

.badge.btn.disabled {
  opacity: 1;
}

.row-grided {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(33% - 40px), 1fr));
  gap: 20px;
  margin: 0 -.75rem;
}

.row-grided .fulled {
  grid-column: 1 / -1;
}

.stat-value {
  font-size: 3vw;
  font-weight: 900;
  line-height: 4vw;
}

.stat-container {
  width: calc(25% - 1rem);
  margin-bottom: 0rem;
}

.stat-container small {
  font-size: 1vw;
}

.logo-profil {
  padding: .5rem;
  position: absolute;
  top: 0;
  z-index: 999;
}

.logo-profil img {
  width: 40px;
  height: 30px;
}

.faq .faq-item {
  margin-bottom: 15px;
  overflow: hidden;
}

.faq .faq-item b {
  font-size: 16px;
  cursor: pointer;
}
.faq .faq-item p {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 100;
  max-height: 0;
  border-left: 1px solid white;
  transition: max-height .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.faq .faq-item.active p {
  max-height: 500px;
}

.btn-link-refresh {
  transform: translateY(-3px);
}

.bg-legend {
    background-color: #d1b100 !important;
    background-image: url(https://www.freepnglogos.com/uploads/crown-png/crown-transparent-crown-image-with-transparent-background-30.png);
    background-repeat: no-repeat;
    background-size: auto 130%;
    background-position: right bottom;
    background-blend-mode: luminosity;
}

@media screen and (max-width: 1200px) {
  .row-grided {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 40px), 1fr));
  }
  
  .stat-container small {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 768px) {
  body {
    background-attachment: fixed;
  }
  input {
    padding-left: 15px !important;
  }

  footer {
    position: relative;
  }

  .row-grided {
    grid-template-columns: repeat(auto-fit, minmax(calc(100% - 40px), 1fr));
  }

  .profileTitle {
    font-size: 7vw;
    max-width: 90vw;
  }
  
  .profileTitle span {
    transform: translateY(0);
  }
  
  .profileTitle img {
    max-width: 10vw;
  }
  .stat-container {
    width: calc(50% - 1rem);
  }
  .stat-value {
    font-size: 7vw;
    font-weight: 900;
    line-height: 7vw;
  }
  
  .stat-container small {
    font-size: 3vw;
  }

  .footer-gifts_texts {
    max-width: 100vw;
    padding-right: 3rem !important;
  }

  .footer-gifts:after {
    width: 100%;
    max-width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    opacity: 0.25;
  }
}
